import { request } from '@/util/request'

export function getDoctorList(data) {
  return request({
    url: '/api/BasicDoctor/GetListWechat',
    method: 'post',
    data,
  })
}

export function getDoctorInfo(params) {
  return request({
    url: '/api/BasicDoctor/GetDoctorInfoWechat',
    method: 'get',
    params,
  })
}
