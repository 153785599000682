<template>
  <div>
    <van-card>
      <template #title>
        <span class="name">{{ info.doctorName }}</span>
        <span class="job">{{ info.deptName }}</span>
        <br />
        <span class="dept">{{ info.title }}</span>
        <br />
      </template>
      <template #thumb>
        <van-image
          v-if="info.doctorPicUrl !== ''"
          round
          width="1.5rem"
          height="1.5rem"
          fit="contain"
          :src="'data:image/png;base64,' + info.doctorPicUrl"
        />
        <van-image
          v-else
          round
          width="1.5rem"
          height="1.5rem"
          :src="require('@/assets/dept_images/Doctor_photo.png')"
        />
      </template>
      <template #footer>
        <p v-show="flag" class="desc" @click="showInfo = true">
          <van-image
            width="0.8rem"
            :src="require('@/assets/dept_images/sc.png')"
          />
          {{ info.des === '' ? '暂无信息' : info.des }}
        </p>
      </template>
    </van-card>
    <van-action-sheet v-model="showInfo" size="500" title="医生擅长">
      <div class="content">
        {{ info.des === '' ? '暂无信息' : info.des }}
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
  import { getDoctorInfo } from '@/api/basic/doctor/doctor'

  export default {
    name: 'Index',
    props: {
      doctorCode: { type: String, default: () => '' },
      doctorName: { type: String, default: () => '' },
      deptName: { type: String, default: () => '' },
    },
    data() {
      return {
        showInfo: false,
        info: {
          doctorName: this.doctorName,
          deptName: this.deptName,
          doctorPicUrl: '',
          title: this.doctorName,
        },
        flag: false,
      }
    },

    created() {
      this.fetchData()
    },
    methods: {
      async fetchData() {
        const { success, data } = await getDoctorInfo({
          DoctorCode: this.doctorCode,
        })
        if (success) {
          this.info = data
          this.flag = true
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .van-card {
    width: 95%;
    margin: auto;
    margin-top: 0.4rem;
    border-radius: 10px;
    background: #fff;
    font-size: 0.426rem;
    line-height: 1.5;
    .van-card__thumb {
      width: 59px;
    }
    .name {
      font-weight: bold;
    }
    .job {
      font-size: 0.38rem;
      color: #616161;
    }
    .dept {
      color: #616161;
    }
    .number {
      background: #1691fe;
      width: fit-content;
      padding: 0 7px;
      color: #fff;
      line-height: 30px;
      border-radius: 15px;
      float: right;
    }
    .desc {
      margin: 0;
      font-size: 0.38rem;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
      color: #676161;
      background: linear-gradient(
        180deg,
        #fff0f1 0%,
        rgba(216, 216, 216, 0) 100%
      );
      padding: 5px;
      box-sizing: content-box;
      border-radius: 10px;
      overflow: hidden;
      height: 2.8em;
    }
    .desc::before {
      display: block;
      width: 3em;
      content: '...更多';
      color: #1691fe;
      position: absolute;
      background: #fffafb;
      right: 7%;
      margin-top: 1.5em;
    }
    .van-card__footer {
      margin-top: -1em;
      text-align: left;
      margin-bottom: 1em;
    }
  }
  .content {
    padding: 16px 16px;
    font-size: 0.4rem;
  }
</style>
