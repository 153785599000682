<template>
  <div>
    <DoctorCard
      :doctor-code="search.DoctorCode"
      :doctor-name="doctorName"
      :dept-name="deptName"
    />
    <div v-if="list.length > 0" class="sourceList">
      <div class="title">仅显示可预约号源</div>
      <van-cell-group class="list">
        <van-cell v-for="(item, index) in list" :key="index">
          <span>{{ date }}</span>
          <span>
            {{ formatDate(item.beginTime) }}-{{ formatDate(item.endTime) }}
          </span>
          <span>{{ item.code }}号</span>
          <div
            :class="[item.count > 0 ? 'number ' : 'number gray']"
            @click="toInfo(item)"
          >
            预约
          </div>
        </van-cell>
      </van-cell-group>
    </div>
    <van-empty v-else description="暂无号源信息，请重新查询" />
  </div>
</template>

<script>
  import moment from 'moment'
  import DoctorCard from '@/components/DoctorCard'
  import { Toast } from 'vant'
  import { getNumberScheduleList } from '@/api/his/his'

  export default {
    name: 'Index',
    components: { DoctorCard },
    data() {
      return {
        search: {
          DeptCode: '',
          DoctorCode: '',
          BeginTime: '',
          EndTime: '',
          DateType: '',
        },
        date: '',
        doctorName: '',
        deptName: '',
        fee: '',
        list: [],
      }
    },
    created() {
      let query = this.$route.query
      this.search.DeptCode = this.$Base64.decode(query.deptCode)
      this.search.DoctorCode = this.$Base64.decode(query.doctorCode)
      this.doctorName = this.$Base64.decode(query.doctorName)
      this.deptName = this.$Base64.decode(query.deptName)
      this.date = this.$Base64.decode(query.date)
      this.search.BeginTime = this.$Base64.decode(query.date)
      this.search.EndTime = this.$Base64.decode(query.date)
      this.search.DateType = this.$Base64.decode(query.dateType)
      this.fee = this.$Base64.decode(query.fee)
      this.fetchData()
    },
    methods: {
      async fetchData() {
        const { data } = await getNumberScheduleList(this.search)
        this.list = data.scoureList
      },

      toInfo(item) {
        console.log(item)
        if (item.count <= 0) {
          Toast('已约满')
          return
        }
        this.$router.push({
          path: '/appointmentConfirm',
          query: {
            deptCode: this.$Base64.encode(item.deptCode),
            doctorCode: this.$Base64.encode(this.search.DoctorCode),
            deptName: this.$Base64.encode(this.deptName),
            doctorName: this.$Base64.encode(this.doctorName),
            beginTime: this.$Base64.encode(item.beginTime),
            endTime: this.$Base64.encode(item.endTime),
            date: this.$Base64.encode(this.date),
            fee: this.$Base64.encode(this.fee),
            code: this.$Base64.encode(item.code),
          },
        })
      },

      formatDate(val) {
        return moment(val).format('HH:mm')
      },
    },
  }
</script>
<style lang="scss" scoped>
  .sourceList {
    width: 95%;
    margin: 0.4rem auto auto;
    background: #fff;
    border-radius: 10px;
    font-size: 0.426rem;
    overflow: hidden;
    .title {
      font-weight: bolder;
      padding: 0.426rem;
      background: linear-gradient(77deg, #e0ebfd 2%, #ffffff 96%, #ffffff 96%);
    }
    .title::before {
      content: '';
      display: inline-block;
      width: 4px;
      height: 1em;
      border-radius: 2px;
      margin-right: 0.5em;
      background: #1691fe;
      overflow: hidden;
    }
    .list {
      padding: 15px;
      .van-cell {
        padding: 0.3rem 0;
        overflow: hidden;
        font-size: 0.4rem;
        overflow: hidden;
        ::v-deep .van-cell__value--alone {
          display: flex;
          align-items: center;
          justify-content: space-around;
        }

        span {
          margin-right: 1em;
        }

        span:nth-child(2) {
          font-weight: bold;
        }
        span:nth-child(3) {
          font-weight: bold;
          color: #1691fe;
        }

        .number {
          background: #1691fe;
          width: fit-content;
          padding: 0 15px;
          color: #fff;
          line-height: 30px;
          border-radius: 15px;
          float: right;
          white-space: nowrap;
        }
        .gray {
          background: #bebebe;
        }
      }
    }
  }
</style>
